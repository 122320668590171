import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-errors',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './errors.component.html',
  styleUrl: './errors.component.css',
})
export class ErrorsComponent implements OnInit {
  @Input() form: FormGroup | undefined;
  @Input() formcontrolname: string | undefined;

  control: FormControl | undefined;

  private translate = inject(TranslateService);

  constructor() {}

  ngOnInit(): void {
    if (this.form && this.formcontrolname) {
      this.control = this.form.get(this.formcontrolname) as FormControl;
    }
  }

  getErrorMessage(): string {
    if (!this.control) {
      return this.getTranslatedMessage('INVALID_FIELD', 'Campo inválido');
    }

    const minError = this.control.errors?.['min'];
    const maxError = this.control.errors?.['max'];

    return this.control.hasError('required')
      ? this.getTranslatedMessage(
          'REQUIRED_FIELD',
          'Dados obrigatórios não informados'
        )
      : this.control.hasError('passwordsNotMatching')
        ? this.getTranslatedMessage(
            'PASSWORDS_NOT_MATCHING',
            'Senhas não são iguais'
          )
        : this.control.hasError('email') || this.control.hasError('pattern')
          ? this.getTranslatedMessage('INVALID_EMAIL', 'Email inválido')
          : this.control.hasError('maxlength')
            ? this.getTranslatedMessage(
                'MAXLENGTH_EXCEEDED',
                'Número de caracteres ultrapassados'
              )
            : this.control.hasError('minlength')
              ? this.getTranslatedMessage(
                  'MINLENGTH_NOT_REACHED',
                  'Número de caracteres não atingido'
                )
              : maxError
                ? this.translate.instant('VALUE_TOO_HIGH', {
                    max: maxError.max,
                  })
                : minError
                  ? this.translate.instant('VALUE_TOO_LOW', {
                      min: minError.min,
                    })
                  : this.control.hasError('pattern')
                    ? this.getTranslatedMessage(
                        'INVALID_FIELD',
                        'Campo inválido'
                      )
                    : this.control.hasError('invalid')
                      ? this.getTranslatedMessage(
                          'INVALID_FIELD',
                          'Campo inválido'
                        )
                      : this.control.hasError('cnpj')
                        ? this.getTranslatedMessage(
                            'INVALID_CNPJ',
                            'CNPJ inválido'
                          )
                        : this.control.hasError('cpf')
                          ? this.getTranslatedMessage(
                              'INVALID_CPF',
                              'CPF inválido'
                            )
                          : this.control.hasError('blank')
                            ? this.getTranslatedMessage(
                                'BLANK_SPACE_ERROR',
                                'Existe um espaço no início do campo'
                              )
                            : this.control.hasError('requiredFileType')
                              ? this.getTranslatedMessage(
                                  'INVALID_FILE',
                                  'Arquivo inválido'
                                )
                              : this.control.hasError('birthDate')
                                ? this.getTranslatedMessage(
                                    'INVALID_BIRTH_DATE',
                                    'Data de Nascimento deve ser igual ou maior que 18'
                                  )
                                : this.getTranslatedMessage(
                                    'INVALID_FIELD',
                                    'Campo inválido'
                                  );
  }

  private getTranslatedMessage(key: string, fallback: string): string {
    const translation = this.translate.instant(key);
    return translation === key || !translation ? fallback : translation;
  }
}
