<div class="flex flex-col gap-0.5 pb-2" [formGroup]="form">
  <label [for]="id" class="font-medium text-tertiary pt-2 pl-1.5">
    {{ label }}
    <span *ngIf="required && label" class="text-red-600">*</span>
  </label>

  <div class="flex items-center">
    <input
      [class]="getClasses()"
      [id]="id"
      [type]="type"
      class="flex-1 h-12 rounded-md outline-none p-3 text-base font-normal text-tertiary"
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [required]="required"
      [class.bg-lightZinc]="readonly"
      [mask]="mask"
      [min]="min"
      [max]="max"
      [thousandSeparator]="thousandSeparator"
      [allowNegativeNumbers]="allowNegativeNumbers"
      (input)="handleInput($event)"
      (blur)="markAsTouched()" />
  </div>

  <div *ngIf="control.invalid && control.touched" class="text-red-600 text-sm">
    <app-errors [formcontrolname]="controlName" [form]="form"></app-errors>
  </div>
</div>
